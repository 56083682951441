// i18next-extract-mark-ns-start products-valdyn-fce

import { VALDYN_NAV } from '@utils/constants';
import { graphql } from 'gatsby';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const ProductValdynPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation(); return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="narrow"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content">
						<p>
							VALDYN has an advanced DYNAMIC BODY element that allows
							finite element (FE) models to be connected to other
							modelling elements to form a fully coupled system that
							can be simulated directly in VALDYN. This is a powerful
							technique for representing complex component stiffness
							that would otherwise be difficult to define in a
							lumped-mass model.
						</p>
						<p>
							VALDYN uses a reduced FE model, which is derived from a
							full FE model using the Craig-Bampton method for
							component mode synthesis (CMS). In the FE model
							connection, nodes are defined that can be normal six
							degrees of freedom (6DoF) FE nodes or ‘constrained’
							nodes representing the average motion of a group of
							normal FE nodes. All FE nodes that don’t contribute to
							the motion of connection nodes are reduced out. Results
							can be back-substituted to obtain component displacement
							and stress that can be animated within FEARCE.
						</p>
						<h2>Key features</h2>
						<ul>
							<li>Simple incorporation of complex geometry</li>
							<li>Can be used in linear frequency domain analysis</li>
							<li>
								Back-substitution for displacements and stresses in
								FEARCE
							</li>
							<li>
								Import and export to Abaqus, Nastran and OptiStruct
							</li>
							<li>Animation of results</li>
						</ul>
					</Trans>
				</div>
				<Aside>
					<Aside.Widget
						title={t("_Applications")}
						className="widget__sibling__pages">
						<AssetList>
							{VALDYN_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
				</Aside>
			</PageSection>
		</Page>
	);
};

export default ProductValdynPage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["products-valdyn-fce", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/valdyn-hero.jpg" }) {
		...imageBreaker
	}
}
`;

